<!--
  PACKAGE_NAME : src\pages\esp\system\theme\color-field.vue
  FILE_NAME : color-field.vue
  AUTHOR : hjsim
  DATE : 2023-07-27
  DESCRIPTION : 테마 설정 -> 테마 컬러 필드
-->
<template>
  <div>
    <caption>
      <strong>선택한 테마</strong>
    </caption>
    <template v-if="fieldData != null ">
      <div class="dx-colorbox-color-result-preview color"
           :style="{backgroundColor: fieldData.value}">
      </div>
    </template>
    <div class="text">
      <DxTextBox
        :value="fieldData && fieldData.key"
        :read-only="true"
        :stylingMode="stylingMode"
        :input-attr="{ 'aria-label': 'key' }"
        class="hex6"
        style="margin-right:0!important;"
      />
    </div>
  </div>
</template>
<script>
import DxTextBox from 'devextreme-vue/text-box';

export default {
  components: {
    DxTextBox,
  },
  props: {
    fieldData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      stylingMode: 'none',
    }

  },
};
</script>
<style>
.color {
  width: 16.5px !important;
}

.hex6 {
  color: rgb(0, 0, 0) !important;
}

.text {
  margin-left: 10px !important;
}
</style>