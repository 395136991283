<!--
  PACKAGE_NAME : src\pages\esp\system\theme\color-item.vue
  FILE_NAME : color-item.vue
  AUTHOR : hjsim
  DATE : 2023-07-27
  DESCRIPTION : 테마 설정 -> 테마 컬러 아이템
-->
<template>
  <div class="custom-item">
    <caption>
      <strong>테마 리스트</strong>
    </caption>
    <div class="dx-colorbox-color-result-preview"
         :style="{ 'background-color': itemData.value}">
    </div>
    <div class="product-name hex6">
      {{ itemData.key }}
    </div>
  </div>
</template>
<script>
export default {
  watch: {},
  props: {
    itemData: {
      type: Object,
      default: () => {
      },

    },
  },
  data() {
    return {}
  }
};
</script>
<style>
.color {
  width: 17.5px;
  height: 17.5px;
  float: left;
}

.hex6 {
  width: 84%;
  margin-left: 2px !important;
  float: right;
}
</style>